<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import { BButton, BCol, BFormGroup, BFormInput, BModal, BRow, BTable } from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { sortObjectValues } from '@/util/helper';
import { isPrimitive } from '@/util/inspect';

export default {
    name: 'QuestionnaireSettings',
    components: {
        TableActionBtns,
        PageTitle,
        BButton,
        BRow,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BFormInput,
        vSelect,
        ProgressLinear
    },
    mixins: [CRUDPageMixin],
    data: () => ({
        inputTypes: [],
        merchantOptions: [],
        dataForModal: {
            name: null,
            type: null,
            merchant: null
        },
        pagination: {
            current: 1,
            totalItem: '',
            perPage: 10
        },
        perPageList: [10, 20, 30, 50, 100],
        loading: false
    }),
    computed: {
        inputTypesTableHeader() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'type',
                    label: this.$t('titles.type')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.inputTypes && this.inputTypes.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.loading;
        }
    },
    watch: {
        '$route.query': {
            handler() {
                this.pagination.current = parseInt(this.$route.query.page);
                this.pagination.perPage = parseInt(this.$route.query.limit);
                this.getInputTypes();
            },
            deep: true
        }
    },
    async mounted() {
        this.query.page = parseInt(this.$route.query.page) || 1;
        this.query.limit = parseInt(this.$route.query.limit) || 10;

        this.loading = true;
        await this.getInputTypes();
        await this.getMerchants();
        this.loading = false;
    },
    methods: {
        async getMerchants() {
            const params = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 10
                }
            };

            await api.coin
                .fetchCoinMerchants(params)
                .then((res) => {
                    this.merchantOptions = res.data.result;
                    this.apps = [{ id: null, name: 'All' }, ...res.data.result];
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getInputTypes() {
            const body = Object.assign({}, { campaign_id: this.campaign_id }, this.query);
            const query = sortObjectValues(body);
            const { data } = await api.inputTypes.getApi(query);
            this.inputTypes = data.result;
            this.pagination = data.pagination;
            // this.inputTypes = sortItems(data.result, 'id');
        },

        async fetchUpdate(item) {
            const { data } = await api.inputTypes.getInputType(item.id);
            this.openModalToUpdate(data);
        },
        async create() {
            let body = this.dataForModal;
            body.merchant_id = body.merchant?.id;
            delete this.dataForModal.merchant;
            const { data } = await api.inputTypes.createApi(body);
            this.inputTypes.push(data.result);
            this.$toast.success(this.$t('success.added'));
            await this.getInputTypes();
        },

        async update() {
            let body = this.dataForModal;
            body.merchant_id = body.merchant?.id;
            delete this.dataForModal.merchant;
            const { data } = await api.inputTypes.updateApi(
                this.updatingItem.id,
                this.dataForModal
            );
            this.updateDataToNewData(data);
            this.$toast.success(this.$t('success.updated.title'));
            await this.getInputTypes();
        },

        async deleteInputType(id, index) {
            try {
                await api.inputTypes.deleteApi(id);
                this.inputTypes.splice(index, 1);
                this.$toast.success(this.$t('success.deleted'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },
        changeCurrentPage(page) {
            const currentPage = this.query.page;
            this.pagination.current = currentPage;
            if (page === currentPage) return;
            if (this.pagination.current > this.pagination.totalItem) {
                this.query.page = 1;
            }
            this.replaceRouter({ ...this.query, page });
        },
        changeFetchLimit() {
            const { query } = this;
            const isNotUpdate = query.limit?.toString() === this.pagination.perPage.toString();
            if (isPrimitive(query.limit) && isNotUpdate) return;
            const localQuery = {
                ...this.query,
                page: 1
            };
            const limit = this.pagination.perPage;
            this.replaceRouter({ ...localQuery, limit });
        },
        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.input_types') }}:</template>
            <template v-if="hasAccess('vote_inputs', 'create')" #button>
                <b-button class="ml-auto" variant="success" @click="isOpenModal = true">
                    {{ $t('add.input_type') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <progress-linear v-if="loading" />
                    <b-table
                        v-else
                        :fields="inputTypesTableHeader"
                        :items="inputTypes"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(actions)="{ item, index }">
                            <table-action-btns
                                :delete-access="{ section: 'vote_inputs', permission: 'delete' }"
                                :delete-handler="deleteInputType"
                                :index="index"
                                :item="item"
                                :update-access="{ section: 'vote_inputs', permission: 'update' }"
                                @openModal="fetchUpdate"
                            />
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="!(pagination.current === 1 && inputTypes.length === 0)">
            <b-col cols="4">
                <div class="d-flex align-items-center">
                    <span> Per page: </span>
                    <v-select
                        v-model="pagination.perPage"
                        :options="perPageList"
                        append-to-body
                        class="ml-2"
                        style="width: 180px"
                        @input="changeFetchLimit"
                    />
                </div>
            </b-col>
            <b-col cols="8">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                    @input="changeCurrentPage"
                />
            </b-col>
        </b-row>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="$t('add.input_type')"
            cancel-variant="danger"
            centered
            size="sm"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    INPUT TYPE NAME    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    INPUT TYPE    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.type') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.type"
                                    :placeholder="$t('enter.type')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <b-col cols="12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.type')"
                            rules="required"
                        >
                            <label>Merchant</label>
                            <v-select
                                v-model="dataForModal.merchant"
                                :options="merchantOptions"
                                label="name"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button variant="success" @click="modalSubmit">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped></style>
